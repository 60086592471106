<template>
<div v-if="isLoadingPage">
<h1>Loading ...</h1>
</div>
<div v-else class="grid-page-wrapper">
    <h1 class="display-5">Work order dashboard: {{ order.code }}</h1>
    <hr>
    <div class="row mb-2">
        <div class="col-md-3">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static bg-card">
                    <h5 class="mb-2 text-primary">Total time (HH:mm:ss)</h5>
                    <strong class="card-text mb-auto">{{ metrics.totalTime }}</strong>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static bg-card">
                    <h5 class="mb-2 text-primary">N. items</h5>
                    <!-- <div class="mb-1 text-muted">Nov 11</div> -->
                    <strong class="mb-auto">{{ metrics.totalItems }} items</strong>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div class="col p-4 d-flex flex-column position-static bg-card">
                <h5 class="mb-2 text-primary">Status</h5>
                <!-- <div class="mb-1 text-muted">Nov 11</div> -->
                <strong class="mb-auto">
                    <span v-if="order.status==0" class="badge bg-secondary">Stand by</span>
                    <span v-else-if="order.status==1" class="badge bg-primary">In progress</span>
                    <span v-else-if="order.status==2" class="badge bg-danger">Stopped</span>
                    <span v-else-if="order.status==3" class="badge bg-success">Finished</span>
                </strong>
            </div>
            </div>
        </div>
        <div class="col-md-3">
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-12">
            <button class="btn btn-secondary dropdown-toggle"                     
                    id="btn-dropdown-export-table"
                    data-bs-toggle="dropdown">
                Export
            </button>
            <ul class="dropdown-menu" aria-labelledby="btn-dropdown-export-table">
                <li><button class="dropdown-item" @click="onBtnExport()"><i class="fa-solid fa-file-csv"></i> CSV</button></li>
            </ul>
        </div>
    </div>
    <div class="grid-wrapper">
        <ag-grid-vue
            class="backoffice-grid ag-theme-alpine"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :paginationAutoPageSize="true"
            :pagination="true"
            @grid-ready="onGridReady"
            @model-updated="onGridModelUpdated"
            :rowData="rowData">
        </ag-grid-vue>
    </div>

    <div class="row mt-2">
        <div class="col">
            <button @click="routeBack" class="btn btn-outline-secondary float-end">
                <i class="bi bi-arrow-return-left"></i> Go back
            </button>
        </div>
    </div>
</div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import { AgGridVue } from "ag-grid-vue3"
import { onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'

import { getOrderInfoDetails } from '../helpers/endpoints';
import { seconds2TimeString } from '../../shared/helpers/dateTimeHelpers'
import { useGrid } from '../../shared/composables/useGrid'


const timeSpendRenderer = (timeSpend) => {
    if (timeSpend.value)
        return seconds2TimeString(timeSpend.value)
    else
        return '-'
}

export default {
    components: { AgGridVue },
    props: {
        idOrder: {
            required: true,
            type: Number
        }
    },
    setup(props) {
        const { 
            defaultColDef, 
            rowData,
            onGridReady,
            exportAsCSV
        } = useGrid(() => { })

        const router = useRouter()
        
        const columnDefs = reactive([
                { headerName: "Username", field: "username", filter: 'agTextColumnFilter' },
                { headerName: "First name", field: "first_name", filter: 'agTextColumnFilter' },
                { headerName: "Last name", field: "last_name", filter: 'agTextColumnFilter' },
                {
                    headerName: 'Total time (HH:mm:ss)',
                    field: 'time',
                    cellRenderer: timeSpendRenderer
                }
            ])
        
        const order = ref()
        const isLoadingPage = ref(true)
        const metrics = reactive({
            'totalTime': 0,
            'totalItems': 0
        })
        
        onMounted(() => {
            getOrderInfoDetails(props.idOrder).then((response) => {
                rowData.value = response.users
                order.value = response.order
                metrics.totalItems = response.order.quantity
                //metrics.totalTime = seconds2TimeString(response.order.time)
                isLoadingPage.value = false
            })
        })
        

        return {
            columnDefs,
            defaultColDef,
            order,
            isLoadingPage,
            rowData,

            onGridReady,
            onBtnExport: () => exportAsCSV(),

            metrics,

            onGridModelUpdated: (params) => {
                const {api, keepRenderedRows} = params
                if (keepRenderedRows) {
                    console.log("Model Updated -> same rows");
                } else {
                    console.log("Model Update -> new rows");
                }

                const rowModel = api.getModel()
                metrics['totalTime'] = 0
                rowModel.forEachNodeAfterFilter((rowNode) => {
                    metrics['totalTime'] += rowNode.data.time
                });

                metrics['totalTime'] = seconds2TimeString(metrics['totalTime'])
            },

            routeBack: () => router.back()
        }
    }
}
</script>
